/*Breaking points

580 - 320 : Mobile
959 - 581 : Tablet
1200 - 960 : PC
 

/**************Skeleton*********************/

/*******************************************/

@media only screen 
	and (min-width:581px) 
	and (max-width:1279px) {
	
	


}


@media only screen
	and (min-width:1025px)
	and (max-height: 960px) {

	body { font-size: 1.1111112vw;}


}


@media only screen 
	and (max-width:1279px) 
	and (max-height:960px) {
	
	body { font-size: 1.5vh !important;}

}



@media only screen 
	and (min-width:581px) 
	and (max-width:768px) {

    body { margin: 0;}

}


@media only screen 
	and (min-width:769px)
	and (max-width:959px) {

	body { margin: 0;}


	
}

@media only screen 
	and (min-width: 960px)
	and (max-width: 1024px) {
	
  body  { font-size: 0.75em; }

	
}

@media only screen 
	and (min-width:1025px) 
	and (max-width:1279px) {

	body  { font-size: 0.675em !important; }


	
}

@media only screen 
	and (min-width:1280px) {
	
	body { margin: 0;}

	
	
}

@media only screen 
	and (min-width:1280px)
	and (max-width:1440px)	{
	
	body { margin: 0;}

	
	
}


@media only screen 
    and (device-width: 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
	
	body { margin: 0;}

	
}

@media only screen 
    and (min-width: 800px) 
    and (height : 600px) { 

	body { margin: 0;}
	
}


@media only screen
    and (max-width: 1024px) {

       
}

@media only screen
	and (max-device-width: 1024px)
    and (max-width: 768px) {

    body  { font-size: 0.75em !important; }
	
	
        
}

@media only screen
	and (max-device-width: 1024px)
	and (max-width: 768px)
	and (device-height: 1024px)
    and (orientation: portrait) {

    body  { font-size: 1vh !important; }
    
}

@media only screen
	and (max-device-width: 1024px)
	and (min-width: 1024px)
    and (orientation: landscape) {

	body  { font-size: 0.875vw !important; }
    
}

@media only screen 
    and (min-width: 600px)
    and (orientation: portrait) {

    body { margin: 0;}  
    
}

@media only screen 
    and (min-width: 1440px)
	and (max-height: 900px) 
	and (min-height: 760px) {

	body  { font-size: 1.625dvh; }

}

@media only screen 
    and (min-width: 1024px)
	and (max-height: 640px) 
	and (min-height: 520px) {

	body { font-size: 1.625dvh !important; /*font-size: 0.6875em;*/ }
		
}

@media only screen 
    and (max-device-width: 1366px)
	and (max-device-height: 1024px) {

	body { font-size: 0.875em;  }
		
}

@media only screen
    and (min-width: 1441px) {
        
	body  { font-size: 1em; }
	
}
	

@media only screen
    and (min-width: 1921px) {

	body  { font-size: 1.125em; }     
    
}

@media only screen
    and (min-width: 2049px) {

    body  { font-size: 1vw; }    
}


@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
	and (orientation : landscape) {

	body { margin: 0;  }
}

@media only screen 
    and (device-width : 812px) 
    and (device-height : 375px) 
	and (orientation : landscape) {

	body { margin: 0;  }
}

@media only screen 
    and (device-width : 568px) 
    and (device-height : 320px) 
	and (orientation : landscape) {

	body { margin: 0; }
}

@media only screen 
	and (max-width: 896px) { 

	body { margin: 0; }
}

@media only screen 
    and (device-width : 414px) 
    and (device-height : 896px) 
	and (max-width: 896px)
    and (-webkit-device-pixel-ratio : 3) { 
		
	body { margin: 0; }
}



/*****************************/




@media only screen and (max-width:1280px){
    html{
      height:100%;
    }
    body{
      font-size: 1vw;
      height:100%;
    }
  }
  
  @media only screen and (max-width:1280px) and (min-height: 961px){
    body{
      font-size: 1.5vh;
      height:100%;
    }
  }
  
  @media only screen and (max-width:1536px) and (min-height: 1153px){
    body{
      font-size: 1.5vh;
      height:100%;
    }
  }
  
  
  
  @media only screen and (max-width:1024px){
    html{
      height:100%;
    }
    body{
      font-size: 0.875em;
      height:100%;
    }
  }


  
  
  
  @media only screen and (min-width:1921px)
    and (max-width: 2560px)
  {
    html{
      height:100%;
    }
    body{
      font-size: 1em;
      height:100%;
    }
  }
  
  
  @media only screen
    and (min-width: 2561px) 
      and (max-device-height: 2400px){
      body {  font-size: 1.325vh; }    
  }
  
  @media only screen
    and (min-width: 3000px) 
      and (min-device-aspect-ratio: 16/9){
      body {  font-size: 1.325vh; }    
  }
  
  
  