/*******Layout css*******/

html { width: 100%; height: 100%; }
body { margin: 0; padding: 0; direction: ltr; text-align: left; word-wrap: break-word; }

body { width: 100%; height: 100%; overflow: auto; position: relative;  }

*, *:before, *:after{ box-sizing:border-box; }

html { text-size-adjust:100%; -webkit-text-size-adjust: 100%; }
body { margin:0;  padding:0; }

body > div { position: relative;height: 100%;overflow-x: hidden;box-sizing: border-box;overflow-y: auto; }

/************Reset Css*********************/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: inherit;
	vertical-align: baseline;
  /*user-select: none;*/
}

/******************Global***********************/

img { margin: 0; max-width: 100%; border: 0 solid transparent; }
h1, h2, h3, h4 , h5 { margin: 0 0 0.0625em;padding: 0 0 0; }

* { box-sizing: border-box;  }
* { -webkit-transition: all .2s 1s, visibility 0s 0s; -moz-transition: all .2s 1s, visibility 0s 0s; 	transition: all .2s 0s, visibility 0s 0s; box-sizing: border-box; }
* :focus { outline: none; }

/**************Skeleton*********************/
