/* You can add global styles to this file, and also import other style files */

@import url(assets/css/master_style.css) only screen and (min-device-width: 581px),(min-width: 581px);
@import url(assets/css/master_style_mobile.css) only screen and (max-device-width: 580px),(max-width: 580px);

body {
  margin: 0;
  padding: 0;
  font-size: 1em;
}
body * {
  box-sizing: border-box;
}

.insertform {
  padding: 2em;
  background: #ffffff80;
  backdrop-filter: blur(1em);
  -webkit-backdrop-filter: blur(1em);
  width: fit-content;
  width: -webkit-fit-content;
  margin: 0 auto;
  border-radius: 1em;
  box-shadow: 0 0.5em 0.5em #00000080, inset 0 0.75em 4em #ffffff80;
}

.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 1em;
  width: 100%;
}

.form-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: 0.5em;
  padding: 1.25em 0 1em;
  position: relative;
  flex: 1 1 auto;
  label {
    font-family: sans-serif;
    font-size: 0.75em;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0 0.125em 0.125em #00000080;
    position: absolute;
    top: 0;
    left: 0;
  }
  input {
    background: transparent;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #fff;
    padding: 0.25em 0.125em 0.25em 0;
    font-size: 1em;
    font-weight: 400;
    color: #fff;
    outline: none;
  }
}



.chat-body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.chat-container {
  width: calc(100% - 2em);
  height: 100vh;
  max-width: 72em;
  padding: 3em 2em;
  background-color: transparent;
  border-radius: 0.7em;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 2em 1em;
  }
}

.chat-messages {
  height: 100%;
  overflow-y: auto;
}

.chat-messages::-webkit-scrollbar { width:0.25em; height: 0.25em; } 
.chat-messages::-webkit-scrollbar-track{ background: #1a477a40; -webkit-box-shadow: inset 0px 0px 0px rgba(0,0,0,0); border-radius:1em; }  
.chat-messages::-webkit-scrollbar-thumb { width: auto; margin: 1px; cursor: pointer; background: #808080; border-radius: 1em;  border: 2px solid transparent; box-shadow: 0 0 0.25 #00000080; }


.message {
  padding: 0.5em 1em 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 0.5em;
}

.message-text {
  word-wrap: break-word;
}
.message-time {
  font-size: 0.75em;
  color: #fff;
}
.message-status {
  font-size: 0.75em;
  color: #fff;
  position: absolute;
  bottom: 0.5em;
  right: 1.5em;
  font-style: italic;
}
input[type="text"]{
    width: 100%;
    padding: 0.5em 1.5em;
    border-radius: 2em;
    font-size: 1em;
    border: 1px solid #6c6c6c;
    box-shadow: inset 0 0.5em 2em #0000008c;
}

input[type="password"]{
    width: 100%;
    padding: 0.5em 1.5em;
    border-radius: 2em;
    font-size: 1em;
    border: 1px solid #6c6c6c;
    box-shadow: inset 0 0.5em 2em #0000008c;
}


button {
  min-width: 10em;
  padding: 0.5em 2em;
  background-color: #131313;
  background-image: linear-gradient(45deg, #131313, #484848);
  color: #fff;
  cursor: pointer;
  text-shadow: 0 0.125em 0.125em #00000080;
  border-radius: 8em;
  position: relative;
  border: 0.5px solid transparent;
  box-shadow: inset -0.5em -0.5em 2em #00000080, 
              inset 0.25em 0.25em 0.5em #ffffff10,  
              inset 0.5em 0.5em 1em #ffffff20, 
              inset 1em 1em 2em #ffffff30, 
              inset 0.125em 0.125em 0.125em #000, 
              inset 0.0625em 0.0625em 0.25em #000,
              0 0.75em 0.5em #00000080;
  user-select: none;
  -webkit-user-select: none;
  transition: all 0.5s ease-in;
}

button:hover, button:focus {
  background-image: linear-gradient(225deg, #13131390, #48484890);
  text-shadow: none;
  box-shadow: none;
  transition: all 0.5s ease-out;
}

button::after {
  position: absolute;
  display: block;
  content: "";
  width: auto;
  height: auto;
  background: linear-gradient(45deg, #0095ff, #01b98e);
  z-index: -1;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  border-radius: 8em;
}


#chat-input-footer {
  padding: 2em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2em;
  input[type=text] {
    width: auto;
    flex: 1 1 auto;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1em 0 3em;
    gap: 1em;
    input[type=text] {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
}

.message.user-message {
  justify-content: flex-end;
  padding: 1em 1em 1.5em;
  flex-direction: column;
  align-items: flex-end;
}


.message-text {
  padding: 1em 2em;
  border: 1.5px solid #9b9b9b70;
  box-shadow: 0 0.25em 0.5em #00000020, 0 0.125em 0.25em #00000080;
}


.user-message .message-text {
  background: linear-gradient(45deg, #03628f, #058676, #06b49f);
  border-radius: 0 2em 2em;
}


.bot-message .message-text {
  background: linear-gradient(225deg, black, #3c3c3c);
  border-radius: 2em 2em 2em 0;
  text-align: left;
  white-space: pre-line;
}

.message.bot-message {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}